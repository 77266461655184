import { FullPageCard, LazyPage, NavigationPage } from '@/app/components'
import { programRequirementsSheetConfig } from '@/app/components/ProgramRequirementsSheet/ProgramRequirementsSheet'
import { ProgressBar } from '@/app/components/ProgressBar/ProgressBar'
import { useProgramRequirements } from '@/app/hooks/useProgramRequirements'
import {
  pageWithErrorBoundary,
  LocationTag,
} from '@/authenticated/components/ErrorBoundary'
import { LoadingAnimation, Text } from '@/components'
import { useBottomSheet } from '@/context/bottom-sheet-context'
import { searchableUtilityProgramsCollection } from '@/reducers/utilityPrograms'
import { UtilityProgram } from '@/types/utilityProgram'
import React, { useEffect, useMemo } from 'react'
import { Route, Switch, useLocation, useParams } from 'react-router-dom'

const UtilityProgramDeviceSelection = React.lazy(
  () => import('./device-selection/device-selection'),
)
const UtilitySelection = React.lazy(
  () => import('./utility-selection/utility-selection'),
)
const ReviewEnrollment = React.lazy(
  () =>
    import(
      '@/app/pages/multi-program-enrollment/review-program/review-program'
    ),
)
const ReviewTOS = React.lazy(() => import('./tos-review/tos-review'))

const EnrollmentState = React.lazy(
  () => import('./enrollment-state/enrollment-state'),
)

export default function MultiProgramEnrollmentPage() {
  const { id: utilityProgramId } = useParams<{ id: string }>()
  const { data: utilityPrograms } =
    searchableUtilityProgramsCollection.useFetch({
      path: `utility_programs/${utilityProgramId}`,
    })
  const utilityProgram = utilityPrograms?.[0]
  const { setBottomSheetContent, config: bottomSheetConfig } = useBottomSheet()
  useEffect(() => {
    // load bottom sheet config for program if it doesn't exist
    if (!bottomSheetConfig && utilityProgram) {
      setBottomSheetContent(
        programRequirementsSheetConfig({
          utilityProgram,
        }),
        true,
      )
    }
  }, [utilityProgram])
  const loadingAnimation = useMemo(
    () => (
      <div className={'flex justify-center items-center h-full'}>
        <LoadingAnimation type={'falling'} />
      </div>
    ),
    [],
  )
  if (!utilityProgram) {
    return (
      <FullPageCard>
        <NavigationPage id="multi-program-page-loading">
          {loadingAnimation}
        </NavigationPage>
      </FullPageCard>
    )
  }
  return <MultiProgramEnrollmentRoot utilityProgram={utilityProgram} />
}

function MultiProgramEnrollmentRoot({
  utilityProgram,
}: {
  utilityProgram: UtilityProgram
}) {
  const { programRequirements, saveForLater, routeToPreviousStep } =
    useProgramRequirements(utilityProgram)
  const location = useLocation()
  const stepIndex = useMemo(() => {
    if (!programRequirements) {
      return 0
    }
    return (
      programRequirements.steps.findIndex((s) => s.path === location.pathname) +
      1
    )
  }, [programRequirements])
  const loadingAnimation = useMemo(
    () => (
      <div className={'flex justify-center items-center h-full'}>
        <LoadingAnimation type={'falling'} />
      </div>
    ),
    [],
  )
  if (!programRequirements) {
    return loadingAnimation
  }
  return (
    <FullPageCard>
      <NavigationPage
        id="multi-program-enrollment-root"
        navigationOptions={
          !location.pathname.includes('enrollment-state')
            ? ['back', 'close']
            : ['nothing']
        }
        backOverride={routeToPreviousStep}
        onCloseClick={saveForLater}
        backDisabled={stepIndex === 1}
      >
        <div className="flex flex-col gap-4 h-full">
          <section>
            {!location.pathname.includes('enrollment-state') && (
              <div className={'w-full flex flex-col gap-1'}>
                <Text variant="body1">
                  Step {stepIndex} of {programRequirements.numberOfSteps}
                </Text>
                <ProgressBar
                  step={programRequirements.step}
                  numberOfSteps={programRequirements.numberOfSteps}
                  textType="percent"
                />
              </div>
            )}
          </section>
          <section className="h-full">
            <Switch>
              <Route
                path="/multi-program-enrollment/:id/device-selection"
                component={pageWithErrorBoundary(
                  LazyPage(UtilityProgramDeviceSelection),
                  {
                    location: LocationTag.UtilityProgramDeviceSelection,
                    functionalityDescription:
                      'Utility Program Device Selection Page',
                    severity: 'critical',
                  },
                )}
              />
              <Route
                path="/multi-program-enrollment/:id/utility-selection"
                component={pageWithErrorBoundary(LazyPage(UtilitySelection), {
                  location: LocationTag.UtilitySelection,
                  functionalityDescription: 'Utility Selection Page',
                  severity: 'critical',
                })}
              />
              <Route
                path="/multi-program-enrollment/:id/review-enrollment"
                component={pageWithErrorBoundary(LazyPage(ReviewEnrollment), {
                  location: LocationTag.ProgramEnrollment,
                  functionalityDescription:
                    'Program Enrollment Confirmation Page',
                  severity: 'critical',
                })}
              />
              <Route
                path="/multi-program-enrollment/:id/review-tos"
                component={pageWithErrorBoundary(LazyPage(ReviewTOS), {
                  location: LocationTag.ReviewTOS,
                  functionalityDescription: 'Review Terms of Service Page',
                  severity: 'critical',
                })}
              />
              <Route
                path="/multi-program-enrollment/:id/enrollment-state/:state"
                component={pageWithErrorBoundary(LazyPage(EnrollmentState), {
                  location: LocationTag.EnrollmentState,
                  functionalityDescription: 'Enrollment State Page',
                  severity: 'critical',
                })}
              />
            </Switch>
          </section>
        </div>
      </NavigationPage>
    </FullPageCard>
  )
}
